/* This example requires Tailwind CSS v2.0+ */
import { Disclosure, Menu, Transition } from "@headlessui/react";
// @ts-expect-error
import springLogo from "../images/spring_logo.png";
// @ts-expect-error
import springLogoWhite from "../images/spring_logo_white.png";
import React, { useEffect, useState } from "react";

import { Link } from "gatsby";

type Props = {
  isTransperent?: boolean;
  isDark?: boolean;
};

const navigation = [
  { name: "Our Projects", href: "/#projects", current: false },
  { name: "About", href: "/#ourStory", current: false },
  { name: "Contact", href: "#contactUs", current: false },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function disableOverscroll(value: boolean) {
  document.body.style.overflow = value ? "hidden" : "unset";
}

let isMenuOpen = false;

const Navbar = (props: Props) => {
  const isTransperent = props.isTransperent ?? false;
  const isDark = props.isDark ?? false;

  let [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", resize.bind(this));
  }, []);

  function resize() {
    if (document.body.style.overflow === "hidden" && window.innerWidth > 640) {
      disableOverscroll(false);
    } else if (isMenuOpen && window.innerWidth <= 640) {
      disableOverscroll(true);
    }
  }

  let handleScroll = () => {
    const offset = window.scrollY;

    let minScroll = window.location.pathname.includes("/projects/drift")
      ? 320
      : 0;
    minScroll = window.location.pathname.includes("/projects/coutre")
      ? 480
      : minScroll;

    minScroll = window.location.pathname.includes("/projects/snackspace")
      ? 480
      : minScroll;
    const minOffset = window.innerWidth <= 640 ? 0 : minScroll;

    if (offset > minOffset) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  return (
    <nav
      className={`sticky top-0  z-50 font-inter w-full -mb-7 bg-white transition-all bg-opacity-0 duration-300 ease-in-out border-b border-borderLines border-opacity-0 ${
        scrolled ? "border-opacity-100" : ""
      } ${isTransperent ? "" : "bg-opacity-100"} ${
        scrolled && isTransperent ? "bg-opacity-100" : ""
      } ${window.innerWidth <= 640 ? "bg-opacity-100" : ""}`}
    >
      {/* <div
        className={`runningLine  duration-500 ease-in-out bg-black100 opacity-0  text-white h-6 flex items-center select-none font-inter ${
          isTransperent ? "" : "opacity-100"
        } ${scrolled && isTransperent ? "opacity-100" : ""} ${
          window.innerWidth <= 640 ? "opacity-100" : ""
        }`}
      ></div> */}
      <Disclosure as="nav" className="">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-6 lg:px-8">
              <div className="relative flex items-center justify-between h-20 ">
                <div
                  className="absolute inset-y-0 right-0 flex items-center md:hidden"
                  onClick={() => {
                    disableOverscroll(!open);
                    isMenuOpen = !open;
                  }}
                >
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center p-5">
                    <span className="sr-only">Open main menu</span>

                    <div className="block w-5 absolute left-1/2 top-1/2   transform  -translate-x-1/2 -translate-y-1/2">
                      <span
                        aria-hidden="true"
                        className={`block absolute h-0.5 w-5 bg-current transform transition duration-400 ease-in-out ${
                          open ? "rotate-45" : "-translate-y-1.5"
                        }`}
                      ></span>
                      <span
                        aria-hidden="true"
                        className={`block absolute h-0.5 w-5 bg-current transform transition duration-400 ease-in-out ${
                          open ? "opacity-0" : "opacity-100"
                        }`}
                      ></span>
                      <span
                        aria-hidden="true"
                        className={`block absolute h-0.5 w-5 bg-current transform transition duration-400 ease-in-out ${
                          open ? "-rotate-45" : "translate-y-1.5"
                        }`}
                      ></span>
                    </div>
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-start justify-start md:items-stretch md:justify-between">
                  <div className="flex-shrink-0 flex items-center justify-center">
                    {/* Show One Logo for Mobile and another for desktop */}
                    {/* <SpringLogo className=" h-6 w-auto fill-black100" /> */}
                    <Link
                      to="/"
                      className="text-xl md:text-2xl font-semibold md:ml-2 select-none"
                    >
                      <img
                        className="hidden md:block h-8 object-contain"
                        alt="Spring Logo"
                        draggable="false"
                        src={
                          isDark && isTransperent && !scrolled
                            ? springLogoWhite
                            : springLogo
                        }
                      />
                      <img
                        className="block md:hidden h-8 object-contain"
                        alt="Spring Logo"
                        draggable="false"
                        src={springLogo}
                      />
                    </Link>
                  </div>
                  <div className="hidden md:block md:ml-6 ">
                    <div className="flex space-x-4 items-center   h-full">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.current ? "text-black100" : "",
                            "px-3 py-2 rounded-md text-base font-regural ",
                            isDark && isTransperent && !scrolled
                              ? "hover:text-white text-gray-200"
                              : "text-black80 hover:text-black100"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Transition
              enter="transition duration-400 ease-out"
              enterFrom="transform opacity-0"
              enterTo="transform opacity-100"
              leave="transition duration-300 ease-out"
              leaveFrom="transform opacity-100"
              leaveTo="transform opacity-0"
            >
              <Disclosure.Panel className="md:hidden bg-white h-screen fixed w-full max-w-7xl mt-0 mx-auto px-6 lg:px-8">
                <div className="">
                  {navigation.map((item) => (
                    <div
                      className=""
                      onClick={() => {
                        isMenuOpen = false;
                        disableOverscroll(false);
                      }}
                    >
                      <div className="w-full h-0 border border-borderLines my-5"></div>
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "text-black100"
                            : " hover:text-black100",
                          "block py-2 rounded-md text-xl font-normal "
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    </div>
                  ))}
                </div>
                <div className="w-full h-0 border border-borderLines mt-6 "></div>
                <div className="flex flex-col space-y-5 text-lg mt-11">
                  <a
                    href="mailto:hello@byspring.co"
                    className="underline underline-offset-1"
                  >
                    hello@byspring.co
                  </a>
                  <a
                    href="tel:+441234567890"
                    className="underline underline-offset-1 hidden"
                  >
                    +44 1234 567 890
                  </a>
                </div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </nav>
  );
};

export default Navbar;
