import React from "react";

interface Props {
  isHidden?: boolean;
}

const Footer = (props: Props) => {
  const isHidden = props.isHidden ?? false;
  return (
    <footer
      className={`mt-11 font-inter bg-black100 text-white -mb-6 ${
        isHidden ? "hidden" : "block"
      }`}
    >
      <div className="max-w-7xl mx-auto px-6  lg:px-8 py-12">
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="">
            <span className="text-2xl lg:text-3xl font-semibold select-none">
              spring
            </span>
            <div className="mt-4 lg:mt-6 flex flex-col space-y-4">
              <a
                href="mailto:hello@byspring.co"
                className="text-base select-none block underline"
              >
                hello@byspring.co
              </a>
              <a className="hidden" href="tel:+441234567890">
                +44 1234 567 890
              </a>
            </div>
          </div>
          <div className="lg:flex lg:flex-col lg:justify-between items-end">
            <p className="hidden lg:block">Designed with ❤️ in London</p>
            <div className="flex flex-col space-y-12 lg:space-y-0 lg:space-x-8 lg:flex-row-reverse lg:space-x-reverse ">
              <div className="mt-4 lg:mt-0 flex flex-col lg:flex-row-reverse lg:space-x-reverse space-y-4 lg:space-y-0 lg:space-x-8">
                <a href="#" className="text-base select-none block">
                  Terms
                </a>
                <a href="#" className="text-base select-none block">
                  Privacy
                </a>
              </div>
              <div className="flex flex-col space-y-4 lg:space-y-0  lg:flex-row lg:space-x-8 lg:justify-between">
                <a
                  href="https://www.instagram.com/byspringco"
                  target="_blank"
                  className="text-base select-none block underline"
                >
                  Instagram
                </a>
                <a
                  href="https://www.linkedin.com/company/byspring"
                  target="_blank"
                  className="text-base select-none block underline"
                >
                  LinkedIn
                </a>
                <p className="block lg:hidden">Designed with ❤️ in London</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

{
  /* <div className="h-48 bg-black100 flex items-center justify-center text-center">
<span className="text-black50">
  Copyright © 2022, By Spring Ltd.
  <br className="" /> All rights reserved.
</span>
</div> */
}
