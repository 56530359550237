import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Footer from "./Footer";
import Navbar from "./Navbar";
import "../styles/global.css";

const duration = 0.4;
const variants = {
  initial: {
    opacity: 0,
    x: 0,
    y: 0,
  },
  animate: {
    opacity: 1,
    y: 0,
    x: 0,
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    y: 0,
    transition: { duration: duration },
  },
};

interface Props {
  // any props that come into the component
  children: any;
  location: any;
}

export const Layout = (props: Props) => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  } else {
    return (
      <AnimatePresence>
        <motion.div
          key={props.location.pathname}
          variants={variants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <div className="">
            <Navbar
              isTransperent={
                props.location.pathname.includes("/projects/drift") ||
                props.location.pathname.includes("/projects/coutre") ||
                props.location.pathname.includes("/projects/snackspace")
              }
              isDark={
                props.location.pathname.includes("/projects/coutre") ||
                props.location.pathname.includes("/projects/snackspace")
              }
            />
            <div className={`mx-auto min-h-full flex-grow`}>
              {props.children}
            </div>
            <Footer />
          </div>
        </motion.div>
      </AnimatePresence>
    );
  }
};

export default Layout;
