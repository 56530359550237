import "./src/styles/global.css";
import React from "react";
import Layout from "./src/components/Layout";

const transitionDelay = 400;

export const wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>;
  };

export const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps: { location },
  getSavedScrollPosition
}) => {
  if (location.action === "PUSH" && location.hash === "") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
    return false;
  } else if (location.hash === "") {
    window.history.scrollRestoration = "manual"
    const savedPosition = getSavedScrollPosition(location);    
    window.setTimeout(
        () => window.scrollTo(...(savedPosition || [0, 0])),
        transitionDelay
      );
      return false;
  } else if (location.hash !== "" && !window.location.pathname.includes(location.pathname)) {
      window.setTimeout(
        () => window.scrollTo(...([0, document.getElementById(location.hash.replace("#", ""))?.offsetTop])),
        transitionDelay
      );
      return false;
  } else {
      window.setTimeout( window.scrollTo(...([0, document.getElementById(location.hash.replace("#", ""))?.offsetTop])), transitionDelay)
      return false;
  }
 
  return false;
};